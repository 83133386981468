import Vue from "vue";
import VueI18n from "vue-i18n";
import getBrowserLocale from "@/util/get-browser-locale";
import messages from "@/locales/en";
Vue.use(VueI18n);
import { Settings } from "luxon";
const loadedLanguages = ["en"];
import langs from "./langs";

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }

// // console.log(getBrowserLocale({ countryCodeOnly: true }));
// let messages = loadLocaleMessages();

export function getUsableLocale() {
  let real = getBrowserLocale({ countryCodeOnly: true });
  if (langs.indexOf(real) >= 0) return real;
  return "en";
}

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

export function loadLanguageAsync(lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang))
    return Promise.resolve(setI18nLanguage(lang));

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}`
  ).then((messages) => {
    const messagesMap = {};
    messagesMap[lang] = messages.default;
    i18n.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

Settings.defaultLocale = getUsableLocale();

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en: messages },
  silentTranslationWarn: true,
});
