import Vue from "vue";
import VueRouter from "vue-router";
import { getCurrentUser } from "../db";
import LandingPage from "@/publicview/LandingPage";
// import Submission from "@/pages/Submission";
// import SubmissionList from "@/pages/SubmissionList";
// import SubmissionForm from "@/pages/SubmissionForm";
// import ContinueForm from "@/pages/ContinueForm";
// import LivePage from "@/publicview/LivePage";
// import AcademyPage from "@/publicview/AcademyPage";
// import TcsCs from "@/publicview/TcsCs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/policy",
    component: () => import("@/publicview/TcsCs"),
    name: "policy",
  },
  // {
  //   path: "/live",
  //   component: () => import("@/publicview/LivePage"),
  //   name: "live",
  // },
  // {
  //   path: "/academy",
  //   component: () => import("@/publicview/AcademyPage"),
  //   name: "academy",
  // },
  {
    path: "/about",
    name: "about",
    component: LandingPage,
  },
  {
    path: "/submission",
    component: () => import("@/pages/Submission"),
    children: [
      {
        path: "",
        name: "submission",
        component: () => import("@/pages/SubmissionList"),
      },
      {
        path: "make/:selectedphase?",
        component: () => import("@/pages/SubmissionForm"),
        props: true,
      },
      {
        path: "continue/:id",
        component: () => import("@/pages/ContinueForm"),
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:token?",
    alias: "/",
    name: "root",
    component: LandingPage,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await getCurrentUser();
  // console.log(to.path);
  if (currentUser && (to.path.startsWith("/login") || to.path == "/"))
    return next("/submission");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!requiresAuth) next();
  if (requiresAuth && !currentUser) next("login");
  else if (requiresAuth && currentUser) next();
});

export default router;
