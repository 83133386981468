<template lang="pug">
q-toolbar.bg-grey-1
  .row.full-width.items-center
    .col-12.col-sm-6.text-center.q-px-sm
      q-chip.text-uppercase.q-pl-md(:icon="getTagIcon(tag)" :icon-selected="getTagIcon(tag)"  size="md" round :outline="!filter.tags[tag]" :selected.sync="filter.tags[tag]" color="primary" text-color="white" v-for="(val,tag) of tags" :key="tag")
        q-tooltip {{$t('tags.'+tag)}}
    .col-12.col-sm-6
      q-select.q-my-sm(emit-value map-options outlined v-model="filter.region" clearable :options='regions' behavior='menu')
        template(v-slot:selected-item="scope")
          q-item(v-bind="scope.itemProps" v-on="scope.itemEvents")
            q-item-section(avatar).flag {{scope.opt.flag}}
            q-item-section
              q-item-label {{scope.opt.label}}

        template(v-slot:option="scope")
          q-item(v-bind="scope.itemProps" v-on="scope.itemEvents")
            q-item-section(avatar).flag {{scope.opt.flag}}
            q-item-section
              q-item-label {{scope.opt.label}}
</template>

<script>
// import find from "lodash/find";
import Language from "@/mixins/Language";
import map from "lodash/map";
import fill from "lodash/fill";
import zipObject from "lodash/zipObject";
import size from "lodash/size";
import { mapActions, mapState, mapGetters } from "vuex";

// let regions = require("../ifrclist.json");
import { ifrcList } from "ifrc-list";
export default {
  props: ["filter"],
  mixins: [Language],
  data: () => {
    return {
      selected_tags: [],
      tags: {},
    };
  },
  watch: {
    tags: {
      immediate: true,
      handler() {
        this.selected_tags = this.tags;
      },
    },
    selected_tags: {
      handler() {
        this.filter.tags = this.selected_tags;
      },
    },
  },
  async mounted() {
    await this.initConfig();
    let alltags = this.config.tag_options;
    // console.log(alltags);
    this.tags = zipObject(
      Object.keys(alltags),
      fill(Array(size(alltags)), false)
    );
    // console.log(this.tags);
  },
  methods: {
    ...mapActions(["initConfig"]),
  },
  computed: {
    ...mapGetters(["getTagIcon"]),
    ...mapState(["config"]),
    regions() {
      let regs = map(ifrcList, (r) => {
        return {
          value: r.code,
          flag: r.flag,
          label: r.name,
        };
      });
      regs.splice(0, 0, {
        value: null,
        label: this.$t("all_regions"),
        flag: "🌍",
      });
      return regs;
    },
  },
};
</script>

<style>
.flag {
  font-size: 2.5em;
}
</style>
