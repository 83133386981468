import filter from "lodash/filter";
import includes from "lodash/includes";
import find from "lodash/find";
import { DateTime } from "luxon";

const getters = {
  getTagIcon(state) {
    return (tag) => {
      let icon = state.config.tag_options[tag] ?? "sell";
      return icon;
    };
  },
  getStats(state) {
    return state.stats;
  },
  getSubmissions(state) {
    return state.submissions;
  },
  getHighlights(state) {
    return state.highlights;
  },
  getUser(state) {
    return state.user;
  },
  getAllSubmissions(state) {
    return state.allsubmissions;
  },
  getUsers(state) {
    return state.users;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  getError(state) {
    return state.error;
  },
  getConfig(state) {
    return state.config;
  },
  // getIsLinkedinUser(state) {
  //   return state.isLinkedinUser;
  // },
  canSubmit: (state) => (wantedphase) => {
    
    console.log("CAN SUBMIT?")

    // return true;

    console.log(`Wanted phase: ${wantedphase}`)

    console.log("Checking if can submit");
    // console.log(state.config);

    if (state.config == null) return false;

    let phase_to_check = wantedphase || state.config.current_phase;

    const currentPhase = find(state.config.phases, {
      code: phase_to_check,
    });

    if (!currentPhase.hasSubmission) return false;

    //got a draft in this phase?
    let can = filter(state.submissions, (s) => {
      return includes(["draft"], s.status) && s.phase == currentPhase.code;
    });

    if (can.length) return false;

    //check if they have reached the limit for this phase:
    const limit = currentPhase.maxSubmissions;

    let done = filter(state.submissions, (s) => {
      return (
        includes(["edited", "published"], s.status) &&
        s.phase == currentPhase.code
      );
    });

    //over limit for this phase?
    if (limit && done.length >= limit) return false;

    // console.log(
    //   DateTime.fromSeconds(currentPhase.closesAt._seconds).diffNow() < 0
    // );

    //outside of date range for this phase?
    if (DateTime.fromSeconds(currentPhase.closesAt._seconds).diffNow() < 0) {
      console.log("out of range");
      return false;
    }

    return true;
  }

};

export default getters;
