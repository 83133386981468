/* eslint-disable no-undef */
(function(root, factory) {
  if (typeof define === "function" && define.amd) {
    define(function() {
      return (root.languageMappingList = factory());
    });
  } else if (typeof exports === "object") {
    module.exports = factory();
  } else {
    root.languageMappingList = factory();
  }
})(this, function() {
  return {
    ar: {
      nativeName: "العربية",
      englishName: "Arabic",
    },
    bn: {
      nativeName: "বাংলা",
      englishName: "Bengali",
    },
    en: {
      nativeName: "English",
      englishName: "English",
    },
    es: {
      nativeName: "Español",
      englishName: "Spanish",
    },
    fa: {
      nativeName: "فارسی",
      englishName: "Persian",
    },
    fr: {
      nativeName: "Français",
      englishName: "French",
    },
    hi: {
      nativeName: "हिन्दी",
      englishName: "Hindi",
    },
    id: {
      nativeName: "Bahasa Indonesia",
      englishName: "Indonesian",
    },
    ja: {
      nativeName: "日本語",
      englishName: "Japanese",
    },
    ko: {
      nativeName: "한국어",
      englishName: "Korean",
    },
    pa: {
      nativeName: "ਪੰਜਾਬੀ",
      englishName: "Punjabi",
    },
    pt: {
      nativeName: "Português",
      englishName: "Portuguese",
    },
    ru: {
      nativeName: "Русский",
      englishName: "Russian",
    },
    sw: {
      nativeName: "Kiswahili",
      englishName: "Swahili",
    },
    tr: {
      nativeName: "Türkçe",
      englishName: "Turkish",
    },
    ur: {
      nativeName: "اردو",
      englishName: "Urdu",
    },
    zh: {
      nativeName: "中文",
      englishName: "Chinese",
    },
  };
});
