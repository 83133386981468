import { auth, firebaseurl, storage } from "../db";
import compact from "lodash/compact";
import find from "lodash/find";
import filter from "lodash/filter";
import { EventBus } from "@/event-bus";
import {
  onAuthStateChanged,
  signInWithCustomToken,
  updateProfile,
} from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import sleep from "await-sleep";

let axios;

async function submissionStatusChanged(submissionid) {
  let done = false;
  let count = 0;
  while (!done) {
    const status = (
      await axios.get(`${firebaseurl}/submissions/submission/${submissionid}`)
    ).data.status;
    console.log("status: ", status);
    if (status !== "draft") return;

    count++;
    await sleep(3000);
    if (count > 5) throw new Error();
  }
}

const actions = {
  resetSubmissions({ commit }) {
    commit("resetSubmissions");
  },
  async getAllSubmissions({ commit, state }, filter) {
    let limit = 8;

    if (!axios) axios = await import("axios");

    let query = {
      limitTo: limit,
    };

    if (filter.region && filter.region != null) query.region = filter.region;

    if (filter.tags) {
      let tt = [];
      for (const [k, v] of Object.entries(filter.tags)) {
        // console.log(k);
        if (v) tt.push(k);
      }
      let tags = tt;
      // console.log(tags);
      if (tags.length) {
        query.tags = tags;
        // ref = ref.where("tags", "array-contains-any", tags);
      }
    } else {
      // query.tags = "*";
    }

    // if (filter.tags.length) query.tags = filter.tags;

    if (state.allsubmissions.length) {
      query.startFrom =
        state.allsubmissions[state.allsubmissions.length - 1].id;
    }

    // console.log(query);

    try {
      // if (!state.submissions) {
      // region, lang, phase, tags:[], limitTo:, startFrom:
      const submissions = (
        await axios.post(`${firebaseurl}/api/submissions`, query)
      ).data;
      // console.log("highlights: ", highlights);
      commit("appendAllSubmissions", submissions.subDocs);
      return submissions.length;
      // }
    } catch (error) {
      return error;
    }
  },
  resetUsers({ commit }) {
    commit("resetUsers");
  },
  async getUsers({ commit, state }, filter) {
    let limit = 50;

    if (!axios) axios = await import("axios");

    let query = {
      limitTo: limit,
    };

    if (filter.region && filter.region != "") query.region = filter.region;

    if (filter.tags) {
      let tt = [];
      for (const [k, v] of Object.entries(filter.tags)) {
        // console.log(k);
        if (v) tt.push(k);
      }
      let tags = tt;
      // console.log(tags);
      if (tags.length) {
        query.tags = tags;
      }
    }

    if (state.users.length) {
      query.startFrom = state.users[state.users.length - 1].id;
    }

    console.log(query);

    try {
      // if (!state.submissions) {
      // region, lang, phase, tags:[], limitTo:, startFrom:
      const users = (await axios.post(`${firebaseurl}/api/users`, query)).data;
      // console.log("highlights: ", highlights);
      commit("appendUsers", users.userDocs);
      return users.length;
      // }
    } catch (error) {
      return error;
    }
  },
  async getHighlights({ commit, state }) {
    if (!axios) axios = await import("axios");

    try {
      if (!state.highlights) {
        const highlights = (
          await axios.get(`${firebaseurl}/api/submissions/highlights`)
        ).data;
        // console.log("highlights: ", highlights);
        commit("setHighlights", highlights);
      }
    } catch (error) {
      return error;
    }
  },
  async getAcademy({ commit, state }) {
    if (!axios) axios = await import("axios");

    try {
      if (!state.academy) {
        const academy = (
          await axios.post(`${firebaseurl}/api/users`, {
            limitTo: 6,
          })
        ).data;
        // console.log("academy: ", academy);
        commit("setAcademy", academy);
      }
    } catch (error) {
      return error;
    }
  },
  async getStats({ commit, state }) {
    if (!axios) axios = await import("axios");

    if (!state.stats) {
      let stats = (await axios.get(`${firebaseurl}/api/statistics`)).data;
      commit("setStats", stats);
    }
  },
  async initConfig({ commit, state }) {
    if (!axios) axios = await import("axios");

    if (!state.config) {
      let config = (await axios.get(`${firebaseurl}/api/config`)).data;
      commit("setConfig", config);
    }
  },
  // async linkedinUser({ commit, state }) {
  //   if (!axios) axios = await import("axios");

  //   if (!state.isLinkedinUser) {
  //     const isLinkedinUser = (
  //       await axios.get(`${firebaseurl}/api/linkedin/${state.user.uid}`)
  //     ).data;

  //     commit("setIsLinkedinUser", isLinkedinUser);
  //   }
  // },
  // async updateSubmission({ state }, payload) {
  //   if (!axios) axios = await import("axios");

  //   try {
  //     const idToken = await auth.currentUser.getIdToken();
  //     console.log(state.user.uid);

  //     const result = (
  //       await axios.post(
  //         `${firebaseurl}/api/submissions/linkedin`,
  //         {
  //           sid: payload.sid,
  //           linkedinPost: payload.linkedinPost,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${idToken}`,
  //           },
  //         }
  //       )
  //     ).data;

  //     return result;
  //   } catch (error) {
  //     return error;
  //   }
  // },
  // async postLinkedIn({ state }, payload) {
  //   if (!axios) axios = await import("axios");

  //   try {
  //     const idToken = await auth.currentUser.getIdToken();

  //     const result = (
  //       await axios.post(
  //         `${firebaseurl}/api/linkedin`,
  //         {
  //           content: payload.content,
  //           youtube: payload.youtube,
  //           uid: state.user.uid,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${idToken}`,
  //           },
  //         }
  //       )
  //     ).data;

  //     return result;
  //   } catch (error) {
  //     return error;
  //   }
  // },
  async signInAction({ commit }, payload) {
    if (!payload.token) return;

    try {
      await signInWithCustomToken(auth, payload.token);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async signOutAction({ commit }) {
    try {
      await auth.signOut();
      commit("setUser", null);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  authAction({ commit }) {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // For first login only since the `auth.user().onCreate` Firebase function (for setting
        // the default displayName if it is null) will be called after `onAuthStateChanged`,
        // set the displayName on the client side for the first time
        if (!user.displayName) user.displayName = "Organisation";
        commit("setUser", user);
      } else {
        commit("setUser", null);
      }
    });
  },
  async uploadMissingAction({ state }, payload) {
    // console.log(payload);
    let submission = find(state.submissions, { id: payload.id });
    let allmedia = filter(submission.media, { type: "raw" });

    // console.log(submission);
    let numtoupload =
      filter(allmedia, { uploadStatus: false }).length +
      filter(allmedia, (f) => {
        return f.error;
      }).length;

    let j = 0;
    if (!axios) axios = await import("axios");
    const idToken = await auth.currentUser.getIdToken();

    //set submission to status="draft" (so that its not stuck in error state)
    await axios.post(
      `${firebaseurl}/submissions/submission/${submission.id}`,
      {
        status: "draft",
        uid: state.user.uid,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    for (let i = 0; i < allmedia.length; i++) {
      // console.log("uploading missing video");
      // let media = filter(submission.medias, { type: "raw" });
      // console.log("status", allmedia[i].status);

      if (
        allmedia[i].uploadStatus == false ||
        (allmedia[i].error && allmedia[i].status == "error")
      ) {
        // console.log("doing it");
        if (payload.files[i]) {
          // console.log("uploading:", allmedia[i].id);
          const uploadRef = ref(
            storage,
            `submissions/${payload.id}/${allmedia[i].id}.mp4`
          );
          const uploadResult = uploadBytesResumable(
            uploadRef,
            payload.files[i]
          );

          uploadResult.on("state_changed", (snapshot) => {
            // console.log(snapshot);
            let progress =
              snapshot.bytesTransferred / snapshot.totalBytes / numtoupload;

            let prog = progress + j / numtoupload;
            // console.log(prog);
            EventBus.$emit("upload_progress", prog);
          });

          await uploadResult;
        }
        j++;
      }
    }

    await submissionStatusChanged(payload.id);
  },
  async submitAction({ state }, payload) {
    payload.submission.navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    // payload.phase = state.config.current_phase;
    payload.noOfVideos = compact(payload.files).length;
    payload.uid = state.user.uid;
    payload.submission.region = (payload.submission.region)?payload.submission.region.toLowerCase():'au';
    if (!axios) axios = await import("axios");
    let medias = (await axios.post(`${firebaseurl}/submissions/`, payload))
      .data;

    for (let i = 0; i < medias.newMediaIds.length; i++) {
      // if (i == 1) throw new Error();

      if (medias.newMediaIds[i] !== null) {
        const uploadRef = ref(
          storage,
          `submissions/${medias.newSubmissionId}/${medias.newMediaIds[i]}.mp4`
        );
        let uploadResult = uploadBytesResumable(uploadRef, payload.files[i]);

        uploadResult.on("state_changed", (snapshot) => {
          // console.log(snapshot);
          let progress =
            snapshot.bytesTransferred /
            snapshot.totalBytes /
            medias.newMediaIds.length;

          let prog = progress + i / medias.newMediaIds.length;
          // console.log(prog);

          // let current = snapshot;
          EventBus.$emit("upload_progress", prog);
        });

        await uploadResult;
      }
    }

    //wait for submission status to change:
    await submissionStatusChanged(medias.newSubmissionId);
  },
  async getSubmissions({ commit, state }, force) {
    if (!axios) axios = await import("axios");
    if (force || !state.submissions) {
      let data = (
        await axios.get(`${firebaseurl}/submissions/${state.user.uid}`)
      ).data;

      commit("setSubmissions", [...data]);
    }
  },

  async updateUserPhoto({ commit, state }, payload) {
    //upload the file:
    const profileRef = ref(storage, `users/${state.user.uid}/profile.jpg`);
    await uploadBytesResumable(profileRef, payload);

    //get a link to the file:
    let url = await getDownloadURL(profileRef);

    // console.log(auth.currentUser);

    await updateProfile(auth.currentUser, {
      photoURL: url,
    });

    commit("setUser", { ...auth.currentUser });
  },

  async updateUserDisplayName({ commit }, payload) {
    await updateProfile(auth.currentUser, {
      displayName: payload,
    });

    commit("setUser", { ...auth.currentUser });
  },
};

export default actions;
