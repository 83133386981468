<template lang="pug">
Transition(name="fade")
  q-banner.vicgov.text-white.drop.q-pa-none.text-black.q-ma-md(v-if="display && config.current_phase && open")
    .row.items-center.justify-center
      .col.text-left.q-pa-sm.q-pb-sm
        .row.text-uppercase.text-center
          .col.text-caption.cap1 {{ $t(`phase.phase_${config.current_phase}`) }}
        .row.text-h6.text-weight-bolder.text-center(style="line-height: 1")
          .col {{ display.days }} :
          .col {{ display.hours }} :
          .col {{ display.mins }} :
          .col {{ display.seconds }}
        .row.justify-around.text-center
          .col {{ $t('countdown_day') }}
          .col {{ $t('countdown_hour') }}
          .col {{ $t('countdown_minute') }}
          .col {{ $t('countdown_second') }}
      .col-auto
      .col-auto.self-stretch.q-pr-md(:class="{ 'bg-cut': $q.screen.gt.xs, 'bg-white col-12 q-pa-md':$q.screen.lt.sm }")
        .row.items-center.full-height.justify-center(:class="{ 'q-pl-xl': $q.screen.gt.xs}")
          q-btn.text-black(
          unelevated
          no-caps
          color="secondary"
          size="lg",
          @click="$emit('submit')",
        :class="{ 'q-ml-lg': $q.screen.gt.xs}"
        ) {{ $t('submit_now') }}
</template>

<script>
import { crono } from "vue-crono";
import { DateTime, Interval } from "luxon";
import { mapActions, mapState } from "vuex";
import find from "lodash/find";

export default {
  mixins: [crono],
  async created() {},
  async mounted() {
    await this.initConfig();
  },
  methods: {
    ...mapActions(["initConfig"]),
    loadTime() {
      if (this.currentThingToCountdownTo) {
        const currentTime = DateTime.now();
        let i;
        if (this.currentThingToCountdownTo <= currentTime)
          i = Interval.fromDateTimes(
            this.currentThingToCountdownTo,
            currentTime
          );
        else
          i = Interval.fromDateTimes(
            currentTime,
            this.currentThingToCountdownTo
          );
        this.countDown = i.toDuration();
      }
    },
  },
  data() {
    return {
      countDown: false,
    };
  },
  computed: {
    ...mapState(["config"]),
    display() {
      if (this.countDown) {
        let secs = this.countDown.as("seconds");

        let days = Math.floor(secs / 86400)
          .toString()
          .padStart(2, 0);
        let leftover = secs % 86400;
        let hours = Math.floor(leftover / 3600)
          .toString()
          .padStart(2, 0);
        leftover = leftover % 3600;
        let mins = Math.floor(leftover / 60)
          .toString()
          .padStart(2, 0);
        leftover = leftover % 60;
        let seconds = Math.floor(leftover)
          .toString()
          .padStart(2, 0);

        return {
          days,
          hours,
          mins,
          seconds,
        };
      } else return false;
    },
    open() {
      if (this.config) {
        let phase = find(this.config.phases, {
          code: this.config.current_phase,
        });

        return (
          DateTime.fromSeconds(phase.closesAt._seconds).diffNow() > 0 &&
          phase.hasSubmission && phase.visible
        );
      } else return false;
    },
    currentThingToCountdownTo() {
      if (this.config) {
        let phase = find(this.config.phases, {
          code: this.config.current_phase,
        });
        return DateTime.fromSeconds(phase.closesAt._seconds);
      } else return 0;
    },
  },
  cron: {
    time: 1000,
    method: "loadTime",
    autoStart: true,
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// .bg-cut {
//   background: white;

//   // background-color: #013a6b;
//   background-image: -webkit-linear-gradient(-30deg, $primary 20%, white 20%);
// }

// html[dir="rtl"] .bg-cut {
//   background-image: -webkit-linear-gradient(30deg, white 80%, $primary 20%);
// }
</style>

<style lang="scss" scoped>
@import "@/styles/quasar.variables.scss";

.cap1 {
  font-size: 1em;
}

/* .bg-rip1 {
  background-color: transparent;
  background-image: url("~@/assets/img/ticker_bg.png");
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  min-height: 118px;
} */

// .bg-rip1 {
//   // border-top: 5px solid $primary;
//   background: $primary;
// }

.drop {
  filter: drop-shadow(0 0 0.75rem #00000066);
}

.vicgov {
  border:1px solid black;
  width:500px;
  max-width:100%;
  border-radius: 16px;
  overflow: hidden;
}
</style>
