/* eslint-disable no-undef */
const langmap = require("@/language-mapping-list");
import { DateTime } from "luxon";
// const iconmap = {
//   digital_health: "un:medicine",
//   mental_health_wellbeing: "",
//   public_health: "",
//   livelihoods_employment: "",
//   food_security: "",
//   education_training: "",
//   social_care_protection: "",
//   communications_campaigns: "",
//   relief: "",
//   climate_environment: "",
//   housing_shelter: "",
//   technology: "un:cell-tower",
//   civic_community_engagement: "",
//   community_development: "",
//   volunteering: "",
//   migration_displacement: "",
//   gender_diversity: "",
//   violence_conflict: "",
//   national_society_development: "",
//   preparedness: "",
// };

export default {
  created() {
    this.$q.iconMapFn = (iconName) => {
      // iconName is the content of QIcon "name" prop

      // your custom approach, the following
      // is just an example:
      if (iconName.startsWith("un:") === true) {
        // we strip the "app:" part
        const name = iconName.substring(3);

        return {
          cls: "huma-" + name,
        };
      }

      if (iconName.startsWith("cop:") === true) {
        // we strip the "app:" part
        const name = iconName.substring(4);
        // console.log("cop icon", name);

        return {
          icon: `img:/${name}.svg`,
          // cls: "cop-" + name,
        };
      }
    };
  },
  methods: {
    codeToLang(val) {
      let l = langmap[val.toLowerCase()];
      return l ? l.nativeName : val;
    },
    getTimeFromNow(field) {
      return DateTime.fromJSDate(field).toRelative();
    },
    getTime(time) {
      // console.log(time._seconds);
      return DateTime.fromSeconds(time._seconds);
    },
  },
};
