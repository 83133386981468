<template lang="pug">
q-layout
  q-page-container
    q-page.text-center.overflow-hidden(padding).kuentobg
      //- EMAIL LOGIN DIALOG (CHANGED FOR GIT)
      q-dialog(v-model="emailLogin")
        q-card(style="width: 800px; max-width: 90vm")
          q-card-section
            .text-h5.q-mb-md.text-center {{ $t('enter_your_email_to_receive_a_one-time_link') }}
            q-input.text-body1(
              ref="emailinput",
              outlined
              type="email",
              v-model="email",
              :label="$t('email')",
              reactive-rules,
              :rules="[(val) => isEmail(val) || $t('email_address_is_required')]"
            )
              template(v-slot:prepend)
                q-icon(:name="farEnvelope")

          //- q-separator
          q-card-actions(align="center").q-ma-sm.q-pt-none
            q-btn.full-width(
              :disable="isDisabled()",
              @click="sentEmail = true; signInWithEmail()",
              filled
              unelevated
              no-caps
              size="lg"
              color="primary"
            )
              q-icon(left, :name="farEnvelope")
              div {{ $t('send_me_an_email') }}
          q-inner-loading.bg-primary(:showing="sentEmail")
            .text-body1.q-mb-md.text-white.text-center(v-if="sentEmail")
              q-icon(:name="matMarkEmailRead", size="lg")
              div {{ $t('signinlink') }}

      q-dialog(v-model="mainLogin")
        q-card.text-center(style="width: 800px; max-width: 90vm")
          .text-h5.q-ma-md {{ $t('start_login') }}
          q-card-section(horizontal)
            //- q-card-section.q-pa-lg.col
            //-   q-btn.liblue(stack, @click="signInWithLinkedIn()", size="md")
            //-     q-icon(:name="fabLinkedinIn", size="lg")
            //-     div {{ $t('sign_in_with_linkedin_to_submit') }}

            //- q-separator(vertical, inset)
            q-card-section.q-pa-lg.col
              q-btn(stack, flat, @click="mainLogin = false; emailLogin = true")
                q-icon(:name="farEnvelope", size="lg")
                div {{ $t('email_login') }}

      .row.justify-center
        .col(style="max-width:800px")
          .column(style="min-height:460px;")
            .col-auto.q-mt-md
              img(
                src="~@/assets/Logo_White.svg",
                style="width:170px;"
              )
            .col-auto
              .text-hero.text-bold.q-mb-md.text-white.q-mt-xl 2023 Multicultural Festivals and Events Program Video Acquittal Portal
              q-btn.q-mt-sm(no-caps color="secondary" size="lg", @click="startLogin()" unelevated style="width:300px;").text-black Start Here
              .text-white.q-mt-md Didn't receive your one-time link email? Contact us at 
                a(href="mailto:mfe-acquittal@kuento.io") mfe-acquittal@kuento.io

      .row.items-center.justify-center.q-my-lg
        Countdown(v-on:submit="startLogin")   
      
      .row.items-center.justify-center
        img.q-mt-lg.q-mr-md(
          src="@/assets/actionlab.svg",
          style="max-height: 70px"
        )
        img.q-mt-lg(
          src="@/assets/vicgov.png",
          style="max-height: 60px"
        )

      .row.justify-center
        .col(style="max-width:1300px;margin-top:160px;")
          //- .border-dotted.q-mb-md
          .row.q-col-gutter-md.items-stretch
            .col-12.full-height
              //- .bg-grey-1.q-pa-sm
              //- .text-bold.text-h5.text-center {{ $t('terms_privacy_policy') }}
              .q-pt-xl.q-pa-sm.text-center
                .text-black {{ $t('oss') }}

                a.text-primary(
                  target="_blank",
                  href="https://gitlab.com/action-lab-aus/kuento"
                ) {{ $t('view_code') }} - v{{version}}
              
                .text-black {{ $t('othercontent') }}
                
                    
                div
                  a(href="/policy").text-primary {{ $t('terms_privacy_policy') }}
               
            
                    
            //- .col-md-6.col-12
              //- .bg-grey-1.full-height(style="padding-bottom:2px;")
                //- .text-weight-bold.text-body1.title.q-pa-sm {{ $t('supporters') }}
                //- .bg-white.text-center.full-height()
                  .row.items-center.justify-center
                    //- img.q-mt-lg(
                    //-   src="@/assets/img/ms.png",
                    //-   style="max-height: 150px"
                    //- )
                  //-   img.q-mt-lg(
                  //-     src="@/assets/img/icrc.jpg",
                  //-     style="max-height: 70px"
                  //-   )
                  //-   img.q-mt-lg(
                  //-     src="@/assets/img/am.png",
                  //-     style="max-height: 90px"
                  //-   )
                   
                  //- .row.items-center.justify-center
                  //-   img.q-mt-lg.q-mr-md(
                  //-     src="@/assets/img/can.png",
                  //-     style="max-height: 80px"
                  //-   )
                  //-   img.q-mt-lg(
                  //-     src="@/assets/img/gb.jpg",
                  //-     style="max-height: 60px"
                  //-   )
                  //-   img.q-mt-lg(
                  //-     src="@/assets/img/it.png",
                  //-     style="max-height: 100px"
                  //-   )
                  .row.items-center.justify-center
                    //- img.q-mt-lg.q-mr-md(
                    //-   src="@/assets/img/rw.jpg",
                    //-   style="max-height: 65px"
                    //- )
                    //- img.q-mt-lg(
                    //-   src="@/assets/img/solferino.svg",
                    //-   style="max-height: 70px"
                    //- )
            
            //- .col-12
            //-   .bg-grey-1.q-pa-sm.full-height
            //-     .text-weight-bold.text-body1.title.q-mb-lg {{ $t('translation_credit_title') }}

            //-     q-spinner-dots(size="2em", color="primary", v-if="!stats || !stats.admin")
            //-     div(v-if="stats && stats.admin")
            //-       //- .text-bold.q-my-sm Translation, Transcription and Moderation
            //-       q-chip(v-for="(person, index) in stats.admin", :key="index" square)
            //-         q-avatar(square)
            //-           q-img(:src="person.photoURL" style="min-height:22px;min-width:20px;")
            //-             template(v-slot:error)
            //-               q-icon(:name="matPerson" color="grey-8" size="sm")
            //-         div {{ person.displayName }}
  //- div(style="height: 160px")
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Countdown from "@/components/Countdown";
import { auth } from "../db";
import reduce from "lodash/reduce";
import includes from "lodash/includes";
import find from "lodash/find";
// import sortBy from "lodash/sortBy";
import Language from "@/mixins/Language";
import { DateTime } from "luxon";
// const regions = require("@/ifrclist.json");
import { ifrcList } from "ifrc-list";
import {
  signInWithEmailLink,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";

import { scroll } from "quasar";
import langs from "../langs";
const { getScrollTarget, setScrollPosition } = scroll;

/* eslint-disable no-control-regex */
const emailreg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default {
  props: ["logo"],
  mixins: [Language],
  components: {
    Countdown,
  },
  data() {
    return {
      version: process.env.PACKAGE_VERSION,
      logoswitch: true,
      eventslang: null,
      accessToken: null,
      email: "",
      emailLogin: false,
      mainLogin: false,
      sentEmail: false,
      loading: true,
      countDown: undefined,
      langSelected: false,
      workflowimages: ["ar", "en", "es", "fr"],
    };
  },
  computed: {
    ...mapGetters(["getTagIcon"]),
    ...mapState(["stats", "user"]),

    numbers() {
      if (!this.stats) return 0;
      return reduce(
        this.stats.submissionStats,
        function(sum, n) {
          return sum + n;
        },
        0
      );
    },
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
    langs() {
      let lngs = [];
      for (let l of langs)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });
      return lngs;
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
  },
  async mounted() {
    const token = this.$route.params.token;
    if (token) {
      await this.signInAction({ token: token });
      this.$router.replace("/submission");
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      // console.log(email);
      if (email)
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            // Clear email from local storage
            window.localStorage.removeItem("emailForSignIn");
            return this.$router.replace("/submission");
          })
          .catch((error) => {
            // Clear email from local storage
            window.localStorage.removeItem("emailForSignIn");
            this.$q.notify({
              position: "top-right",
              color: "secondary",
              textColor: "white",
              icon: this.matWarning,
              message: this.$t("link_expired"),
            });
            console.error(error);
          });
      else
        this.$q.notify({
          position: "top-right",
          color: "secondary",
          textColor: "white",
          icon: this.matWarning,
          message: this.$t("link_expired"),
        });
    }

    this.loading = false;

    // Get contributors

    // TODO: replace when in Phase 2
    await Promise.all([this.initConfig()]);

    this.getHighlights();
    // this.getAcademy();
    this.getStats();
  },
  methods: {
    onSelectChange(val) {
      if (val) this.langSelected = true;
      else this.langSelected = false;
    },
    noLangs(sessions) {
      let islang = false;
      sessions.forEach((element) => {
        if (element.langs.includes(this.eventslang)) islang = true;
      });
      return islang;
    },
    pastEvents(sessionAt) {
      return sessionAt <= DateTime.now();
    },
    isEventLang(session) {
      return session.langs.includes(this.eventslang);
    },
    getEventImg(event) {
      let ln = "en";
      // console.log(find(this.eventlangs, this.$i18n.locale));
      if (event.imgs.includes(this.$i18n.locale)) ln = this.$i18n.locale;
      return `/img/events/${ln}/${event.src}`;
    },
    getWorkflowImg(layout) {
      // console.log(this.$i18n.locale);
      let lang = this.$i18n.locale;
      if (!includes(this.workflowimages, this.$i18n.locale)) lang = "en";
      // console.log(`/img/workflows/${layout}/${lang}.png`);
      return `/img/workflows/${layout}/${lang}.png`;
    },
    startLogin() {
      if (this.user) this.$router.push("/submission");
      else this.emailLogin = true;
    },
    getSubRegion(user) {
      if (user.submissions.length)
        return this.getRegion(user.submissions[0].region);
      else return "";
    },
    getRegion(code) {
      return find(ifrcList, { code: code });
    },

    goto(ref) {
      const el = this.$refs[ref].$el;
      const target = getScrollTarget(el);
      const offset = el.offsetTop;
      const duration = 400;
      setScrollPosition(target, offset, duration);
    },
    onIntersection(entry) {
      // console.log(entry);
      this.logo.display = !entry.isIntersecting;
    },
    isEmail(val) {
      return emailreg.test(val);
    },
    isDisabled() {
      if (!this.$refs.emailinput) return true;
      // console.log(this.$refs.emailinput.hasError);
      return this.$refs.emailinput.hasError;
    },
    ...mapActions([
      "signInAction",
      "googleSignInAction",
      "getStats",
      "getHighlights",
      // "getAcademy",
      "initConfig",
    ]),

    // signInWithLinkedIn() {
    //   window.location.href = `${process.env.VUE_APP_FUNCTION_URL}/redirect`;
    // },
    async signInWithEmail() {
      // console.log(window.location.hostname);
      const actionCodeSettings = {
        url:
          window.location.hostname === "localhost"
            ? "http://localhost:8080/"
            : process.env.VUE_APP_WEB_APP,
        handleCodeInApp: true,
      };

      auth.languageCode =
        window.navigator.userLanguage || window.navigator.language;

      sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", this.email);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`${errorCode}: ${errorMessage}`);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/quasar.variables.scss";

@media only screen and (min-width: 600px) {
  .text-hero {
    font-size: 2.6em;
    font-weight: bold;
  }

  .text-title {
    font-weight: 400;
    font-size: 3.4em;
  }
}

@media only screen and (max-width: 600px) {
  .text-hero {
    font-size: 1.2em;
  }

  .text-title {
    font-weight: 400;
    font-size: 2.4em;
  }
}

.background1 {
  z-index: -10;
  position: relative;
  /* margin-left: 2em; */
  margin-bottom: -2em;
  width: 90%;
  /* transform: scaleX(-100%); */
}

.background2 {
  /* transform: scaleX(-100%); */
  /* margin-right: -20%; */
  width: 140%;
  margin-left: -10%;
}

a:link {
  color: $secondary;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: $secondary;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  // color: pink;
  background-color: transparent;
  text-decoration: underline;
}

.mainlogo {
  width: 70%;
}

.register {
  bottom: 1em;
  transform: translateX(-50%);
}

.liblue {
  // background: #0a66c2;
  color: white;
}

.title {
  font-size: 1.8em;
  text-transform: uppercase;
  font-style: italic;
  .text-caption {
    font-size: 0.6em;
  }
}

.dots {
  margin-top: 15px;
}

.border-dotted {
  border-top: 1px dashed $grey-6;
}

.kuentobg {
  background-image:url('../assets/landing.jpg');
  background-color: #FFF9ED;
  background-repeat: no-repeat;
  background-size: 920px;
  background-position-x: center;
}
</style>

<style src="@platyplus/humanitarian-icons/dist/icons.css">
/* global styles */
</style>
