<template lang="pug">
q-layout(style="background-color: #FFF9ED;")
  q-header.sticky(reveal v-if="canShowHeader" style="border-bottom:1px solid #FFF9ED;border-radius: 0 0 16px 26px").position-relative
    q-toolbar.q-pa-md()
      q-btn.on-left(
        :icon="whichIcon",
        flat,
        color="white",
        dense,
        round,
        :to="whereToGo",
        v-if="canShowBtn",
        aria-label="back"
      )
      //- .col-auto.bg-primary.xs-hide(style="position:absolute;top:0px;bottom:0px;margin-start:50px;padding-top:25px;padding-left:20px;padding-right:20px;")
      //-   img.logo(
        
      //-   src="~@/assets/img/solferino.svg"
      //- )
      q-space.xs-hide
      //- .text-h4.text-grey-9 Kuento
      img(src="./assets/Logo_White.svg" style="height:25px")
      //- a.solferino.absolute-center(
      //-   href="https://solferinoacademy.com",
      //-   target="_blank"
      //- )
        //- img(src="~@/assets/img/solferino.svg")
      q-space.q-my-md
      q-btn(@click="signOut", flat, v-if="user", color="white") {{ $t('logout') }}
      LocaleSwitcher(style="display:none;")
    FilterControls(v-if="logo.filter", :filter="filter")
  q-page-container
    router-view(:key="$route.fullPath", :logo="logo", :filter="filter")
  //- cookie-law.bg-secondary.text-white(ref="cookies")
  //-   .full-width(slot-scope="props")
  //-     .row.full-width
  //-       .col-12.q-mb-sm
  //-         div(slot="message")
  //-           | {{ $t('cookie_message') + ' ' }}
  //-           router-link.text-white(to="/policy") {{ $t('read_more') }}
        //- .col-auto.q-mb-sm
          //- q-btn.on-left(@click="props.decline()", outline) {{ $t('use_cookies') }}
        //- .col-auto
          //- q-btn.on-left(@click="addGA(); props.accept()", outline) {{ $t('use_cookies_and_google') }}
</template>

<script>
import { mapActions, mapState } from "vuex";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import FilterControls from "@/components/FilterControls";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  data: () => {
    return {
      logo: {
        display: true,
        filter: false,
      },
      filter: {
        region: null,
        tags: [],
      },
    };
  },
  async mounted() {
    this.authAction();
    // console.log(this.$refs.cookies.isAccepted());
    // if (this.$refs.cookies.isAccepted())
    this.addGA();
  },
  meta() {
    return {
      title: this.$t("page_title"),
      meta: {
        description: { name: "description", content: this.$t("landing1") },
        keywords: {
          name: "keywords",
          content: Object.values(this.$t("tags")).join(", "),
        },
      },
    };
  },
  components: { LocaleSwitcher, FilterControls, CookieLaw },
  computed: {
    whereToGo() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "about":
          return "/submission";
        case "live":
        case "academy":
          return "/";
        case "submission":
          return "/about";
        default:
          return "/";
      }
    },
    whichIcon() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "about":
          return this.matPerson;
        default:
          return this.matArrowBack;
      }
    },
    canShowBtn() {
      return this.$route.name != "root";
    },
    canShowHeader(){
      console.log(this.$route.name);
      return this.$route.name !== 'about' && this.$route.name !== 'root';
    },
    ...mapState(["user", "config"]),
  },
  methods: {
    deleteGA(cookieconsent_name) {
      var keep = [cookieconsent_name, "DYNSRV"];

      document.cookie.split(";").forEach(function(c) {
        c = c.split("=")[0].trim();
        if (!~keep.indexOf(c))
          document.cookie =
            c + "=;" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      });
    },
    addGA() {
      // console.log("add ga");
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = "true";
      s.src = "https://www.googletagmanager.com/gtag/js?id=G-TMH1WZMJ0C";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);

      //it is absolutely crucial to define gtag in the global scope
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-TMH1WZMJ0C", { anonymize_ip: true });
      // you can add facebook-pixel and other cookies here
    },

    async signOut() {
      await this.signOutAction();
      this.$router.replace("/");
    },
    ...mapActions(["authAction", "signOutAction"]),
  },
};
</script>

<style scoped>
.logo {
  opacity: 1;
  transition: opacity 0.3s;
  width: 260px;
}

.logogone {
  opacity: 0;
}

.solferino {
  width: 130px;
  margin-top: 4px;
}

.q-header--bordered {
  border-color: #aaa;
}
</style>
