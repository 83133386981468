import Vue from "vue";
import "./styles/quasar.scss";
import "quasar/dist/quasar.ie.polyfills";
// import "@quasar/extras/material-icons/material-icons.css";
import iconSet from "quasar/icon-set/svg-material-icons";
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/Meta.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

Vue.use(Quasar, {
  iconSet: iconSet,
  plugins: {
    Meta,
    Loading,
    Notify,
  },
});
