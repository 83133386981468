import { render, staticRenderFns } from "./LandingPage.vue?vue&type=template&id=7f411ef8&scoped=true&lang=pug&"
import script from "./LandingPage.vue?vue&type=script&lang=js&"
export * from "./LandingPage.vue?vue&type=script&lang=js&"
import style0 from "./LandingPage.vue?vue&type=style&index=0&id=7f411ef8&scoped=true&lang=scss&"
import style1 from "@platyplus/humanitarian-icons/dist/icons.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f411ef8",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer,QPage,QDialog,QCard,QCardSection,QInput,QIcon,QSeparator,QCardActions,QBtn,QInnerLoading,QSpinnerDots,QChip,QAvatar,QImg});
